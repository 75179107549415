.app-sidebar {
  width: 240px;
  &_head {
    padding: 0 1rem;
    .app-logo {
      height: 92px;
      margin: 0;
    }
  }
  &_nav {
    padding: 1rem;
  }

  & + main {
     margin-left: 240px;
     padding: 100px 1rem;
  }
}
