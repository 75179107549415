body {
  background-color: rgba(255,255,255, 1) !important;
}

main {
  display: flex;
  flex: 1;
}

figure {
  margin: 0;
  padding: 0;
  line-height: 0;
}

@import './sidebar';
@import './ctastrip';
@import './list';
