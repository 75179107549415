.app-listcollapsible-item {
  &.active {

  }
  & + .app-collapsible {
    .app-bullet {
      width: 0.2rem;
      height: 0.2rem;
      border-radius: 50%;
      background-color: currentcolor;
      transition: all .5s ease;
    }
  }
}